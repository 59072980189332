<template>
  <div>
    <c-tab
      ref="nearmissTab"
      type="horizon"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :height="tabHeight"
      :dense="true"
      @tabClick="tabClick"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam="popupParam"
          :tabKey="tabKey"
          @changeStatus="changeStatus"
          @closePopup="closePopup"
          @transInfo="transInfo"
        />
      </template>
    </c-tab>
  </div>
      <!-- class="full-height" -->
</template>

<script>
import { uid } from 'quasar';
export default {
  name: 'near-accident-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      splitter: 5,
      tab: 'nearRegisterInfo',
      addTabItems: [],
      tabItems: [],
      tabKey: '',
    };
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.tabItems = [
        { name: 'nearRegisterInfo', icon: 'edit', label: '아차사고 정보', component: () => import(`${'./nearRegisterInfo.vue'}`) },
      ];
      this.addTabItems = [
        { name: 'nearCausePrevention', icon: 'auto_fix_normal', label: '개선관리', component: () => import(`${'./nearCausePrevention.vue'}`) },
      ];
      if (this.popupParam.iimAccidentId) {
        this.tabItems = this.tabItems.concat(this.addTabItems);
      }
    },
    changeStatus() {
      this.tabItems = this.tabItems.concat(this.addTabItems);
    },
    closePopup(data) {
      this.$emit('closePopup', data);  
    },
    transInfo(data) {
      this.popupParam.iimAccidentId = data.iimAccidentId;
    },
    tabClick() {
      this.tabKey = uid()
    },  
  }
};
</script>
